import styled, { createGlobalStyle } from 'styled-components'
import { rgba, lighten, darken } from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, errorColor, correctColor } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';
import { animations, fadeIn } from 'component/common/style/Animations';

const GlobalStyle = createGlobalStyle`
  body { margin:0; padding:0; box-sizing:border-box; background:${subColor}; }
  div, h1, h2, h3, h4, p, a, span, input, textarea, ul, li, header, footer { margin:0; padding:0; box-sizing:border-box; -webkit-tap-highlight-color:none; }
  a, a:hover, a:active { text-decoration:none; background:none; color:${linkColor}; }
  p { padding:10px; }

  // スクロールバー全体 
  div::-webkit-scrollbar { width:3px; height:3px; }
  // スクロールバーの軌道
  div::-webkit-scrollbar-track { border-radius:1000px; box-shadow:inset 0 0 6px ${rgba(mainColor,0)}; }
  // スクロールバーの動く部分
  div::-webkit-scrollbar-thumb { background:${rgba(mainColor,0.5)}; border-radius:1000px; box-shadow:0 0 0 1px ${rgba(mainColor,0)}; }

  // テキスト選択時の背景色と文字色 
  p::selection, h1::selection, h2::selection, h3::selection, h4::selection, a::selection, div::selection, span::selection, label::selection, input::selection, textarea::selection, tspan::selection, tr::selection, th::selection, td::selection {
    background:${edgeColor}; color:${subColor};
  }
  p::-moz-selection, h1::-moz-selection, h2::-moz-selection, h3::-moz-selection, h4::-moz-selection, a::-moz-selection, div::-moz-selection, span::-moz-selection, label::-moz-selection, input::-moz-selection, textarea::-moz-selection, tspan::-moz-selection, tr::-moz-selection, th::-moz-selection, td::-moz-selection { 
    background:${edgeColor}; color:${subColor};
  }

  // font-famiry
  @import url('https://fonts.googleapis.com/css?family=Rubik:300,400&text=0123456789&display=swap'); // 数字用フォント Rubik
  @import url('https://fonts.googleapis.com/css?family=Cairo:400,900|Titillium+Web&display=swap'); // 英語用フォント Cairo
  @import url('https://fonts.googleapis.com/css2?family=Arimo&display=swap'); // タイマーの数字用フォント Arimo
  @import url('https://fonts.googleapis.com/css2?family=Kosugi+Maru&display=swap'); // 日本語 Kosugi Maru
  body, h1, h2, h3, h4, p, a { font-family:'Rubik', 'Cairo', 'Arimo', 'Kosugi Maru', sans-serif; }

  // アニメーション
  ${animations}
`

const ConsoleField = styled.div`
  &&& {
    ${fadeIn(2,0,0)}
  }
`

const ClearBoth = styled.div`
  &&& {
    clear:both;
  }
`

export { GlobalStyle, ConsoleField, ClearBoth };
