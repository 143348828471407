import React from 'react';
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの

// style
import {ResultsLayerStyle, Item} from 'component/common/style/ResultsLayer';
import { lemmaColor, formColor, orthColor, pronColor } from 'component/common/style/Colors';

class ResultsLayer extends React.Component {

  static propTypes = {
    search: PropTypes.func,
    showData: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      lemmas:this.props.lemmas, forms:this.props.forms, orths:this.props.orths, prons:this.props.prons,
      selectItem:'',
    }
  }

  handleAccordion = (layerNum,id) => {
    let lemmas = this.state.lemmas.slice();
    let forms = this.state.forms.slice();
    let orths = this.state.orths.slice();
    let prons = this.state.prons.slice();

    if (layerNum == 0) {
      // 選択した語彙素の下位項目を開閉
      const lemmaIndex = lemmas.findIndex(lemma => lemma.lemmaID == id);
      if (lemmas[lemmaIndex].acordion) {
        lemmas[lemmaIndex].acordion = false;
      } else {
        lemmas[lemmaIndex].acordion = true;
      }

      for (var formIndex=0; formIndex<forms.length; formIndex++) {
        if (forms[formIndex].lemma == id) {
          if (forms[formIndex].close) {
            forms[formIndex].acordion = true;
            forms[formIndex].close = false;
            for (var orthIndex=0; orthIndex<orths.length; orthIndex++) {
              if (orths[orthIndex].form == forms[formIndex].formID) {
                orths[orthIndex].close = false;
              }
            }
            for (var pronIndex=0; pronIndex<prons.length; pronIndex++) {
              if (prons[pronIndex].form == forms[formIndex].formID) {
                prons[pronIndex].close = false;
              }
            }
          } else {
            forms[formIndex].acordion = false;
            forms[formIndex].close = true;
            for (var orthIndex=0; orthIndex<orths.length; orthIndex++) {
              if (orths[orthIndex].form == forms[formIndex].formID) {
                orths[orthIndex].close = true;
              }
            }
            for (var pronIndex=0; pronIndex<prons.length; pronIndex++) {
              if (prons[pronIndex].form == forms[formIndex].formID) {
                prons[pronIndex].close = true;
              }
            }
          }
        }
      }
    } else {
      // 選択した語形の下位項目を開閉
      const formIndex = forms.findIndex(form => form.formID == id);
      if (forms[formIndex].acordion) {
        forms[formIndex].acordion = false;
      } else {
        forms[formIndex].acordion = true;
      }

      for (var orthIndex=0; orthIndex<orths.length; orthIndex++) {
        if (orths[orthIndex].form == id) {
          if (orths[orthIndex].close) {
            orths[orthIndex].close = false;
          } else {
            orths[orthIndex].close = true;
          }
        }
      }
      for (var pronIndex=0; pronIndex<prons.length; pronIndex++) {
        if (prons[pronIndex].form == id) {
          if (prons[pronIndex].close) {
            prons[pronIndex].close = false;
          } else {
            prons[pronIndex].close = true;
          }
        }
      }
    }

    this.setState({ lemmas:lemmas, forms:forms, orths:orths, prons:prons, });
  }


  // 通常クリックとダブルクリックを区別する際につかう変数
  timer = 0;
  delay = 200;
  prevent = false;

  singleClick = (event,type,id) => {

    this.timer = setTimeout(() => {
      if (!this.prevent) {
        this.showData(event,type,id);
      }
    }, this.delay);
  }


  showData = (event,type,id) => {

    if (event.target.tagName != 'I') {

      let selectItem;
      let lemma = [];
      let form = [];
      let orth = [];
      let pron = [];
      if (type == 'lemma') {
        selectItem = 'lemma-' + id;
        lemma = this.state.lemmas.find(lemma => lemma.lemmaID == id);
      } else if (type == 'form') {
        selectItem = 'form-' + id;
        form = this.state.forms.find(form => form.formID == id);
        lemma = this.state.lemmas.find(lemma => lemma.lemmaID == form.lemma);
        pron = this.state.prons.find(pron => pron.form == form.formID);
      } else if (type == 'orth') {
        selectItem = 'orth-' + id;
        orth = this.state.orths.find(orth => orth.orthID == id);
        form = this.state.forms.find(form => form.formID == orth.form);
        pron = this.state.prons.find(pron => pron.form == orth.form);
        lemma = this.state.lemmas.find(lemma => lemma.lemmaID == form.lemma);
      } else if (type == 'pron') {
        selectItem = 'pron-' + id;
        pron = this.state.prons.find(pron => pron.pronID == id);
        form = this.state.forms.find(form => form.formID == pron.form);
        lemma = this.state.lemmas.find(lemma => lemma.lemmaID == form.lemma);
      }

      this.setState({ selectItem:selectItem, });
      this.props.showData(type, lemma, form, orth, pron);
    }

  }

  search = (event, searchTarget, searchWord, type, id) => {
    clearTimeout(this.timer);
    this.prevent = true;
    this.setState(
      {},
      () => {
        if (type != 'pron') {
          this.props.search(event, searchTarget, searchWord);
        }
        setTimeout(() => {
          this.prevent = false;
        }, this.delay);
      }
    );
  }

  render() {

    let lemmas;
    if (this.state.lemmas) {
      lemmas = this.state.lemmas.map((lemma) => {
        let forms;
        if (this.state.forms) {
          forms = this.state.forms.map((form) => {
            let orths;
            if (this.state.orths) { 
              orths = this.state.orths.map((orth) => {
                if (orth.form == form.formID) {
                  return (
                    <Item layerNum={2} typeColor={orthColor} close={orth.close} select={this.state.selectItem == 'orth-' + orth.orthID ? true : false} hit={orth.hit ? true : false} onClick={ (event) => {this.singleClick(event,'orth',orth.orthID)} } onDoubleClick={ (event) => {this.search(event, 'orthBase', orth.orthBase, 'orth', orth.orthID)} }>
                      <p><span className='type'>書</span>{orth.orthBase}</p>
                    </Item>
                  );
                }
              });
            }

            let prons;
            if (this.state.prons) {
              prons = this.state.prons.map((pron) => {
                if (pron.form == form.formID) {
                  return (
                    <Item layerNum={2} typeColor={pronColor} close={pron.close} select={this.state.selectItem == 'pron-' + pron.pronID ? true : false} hit={pron.hit ? true : false} onClick={ (event) => {this.singleClick(event,'pron',pron.pronID)} } onDoubleClick={ (event) => {this.search(event, 'pronBase', pron.pronBase, 'pron', pron.pronID)} }>
                      <p><span className='type'>音</span>{pron.pronBase}</p>
                    </Item>
                  );
                }
              });
            }

            return (
              <div>
                <Item layerNum={1} typeColor={formColor} close={form.close} select={this.state.selectItem == 'form-' + form.formID ? true : false} hit={form.hit ? true : false} onClick={ (event) => {this.singleClick(event,'form',form.formID)} } onDoubleClick={ (event) => {this.search(event, 'formBase', form.formBase, 'form', form.formID)} }>
                  <p>
                    <i className={form.acordion ? 'fas fa-caret-down' : 'fas fa-caret-up'} onClick={ () => {this.handleAccordion(1,form.formID)} }></i>
                    <span className='type'>形</span>{form.formBase}
                  </p>
                </Item>
                {orths}
                {prons}
              </div>
            );
          });
        }
        return (
          <div>
            <Item layerNum={0} typeColor={lemmaColor} select={this.state.selectItem == 'lemma-' + lemma.lemmaID ? true : false} close={lemma.close} hit={lemma.hit ? true : false} onClick={ (event) => {this.singleClick(event,'lemma',lemma.lemmaID)} } onDoubleClick={ (event) => {this.search(event, 'lemma', lemma.lemma, 'lemma', lemma.lemmaID)} }>
              <p>
                <i className={lemma.acordion ? 'fas fa-caret-down' : 'fas fa-caret-up'} onClick={ () => {this.handleAccordion(0,lemma.lemmaID)} }></i>
                <span className='type'>素</span>{lemma.lemma}</p>
             </Item>
            {forms}
          </div>
        );
      });
    }

    return (
      <ResultsLayerStyle>
        {lemmas}
      </ResultsLayerStyle>
    );


  }
}

export default ResultsLayer;
