import styled from 'styled-components'
import { rgba, lighten, darken, bgGradation } from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, brown, navy, purple, green } from 'component/common/style/Colors';
import { fadeIn } from 'component/common/style/Animations';
import { colorFlash } from 'component/common/style/Animations';

const ResultsContentStyle = styled.div`
  &&& {
    width:80%; height:calc(100% + 3px); overflow:scroll; float:right;
    background:${subColor};
  }
`

// transformを定義するとRechartsライブラリのToolTipが最後にレンダリングされたもの以外は表示されないっぽいのでfadeInアニメーションは使ってはいけない
const ContentBlock = styled.div`
  &&& {
    width:100%; height:auto; margin-bottom:30px;
    h2 { color:${ props => props.typeColor ? props.typeColor : mainColor}; font-size:25px; font-weight:100; padding:5px 10px; 
         border-bottom:2px solid ${ props => props.typeColor ? props.typeColor : mainColor}; }
    h3 { font-size:15px; font-weight:100;  margin-top:40px; padding:5px 20px; display:inline-block; width:100%; }
    p { color:${ props => props.typeColor ? props.typeColor : mainColor}; font-size:15px; line-height:23px;
      label { color:${subColor}; background:${ props => props.typeColor ? props.typeColor : mainColor}; margin-right:5px; padding:1.5px 3px; border-radius:5px; font-size:13px; }
      .dot { ${colorFlash(1)} }
      .large { font-size:23px; }
    }
    a { color:${linkColor}; text-decoration:underline;  margin:5px 20px; padding:0; }
  }
`

const ExamplesStyle = styled.table`
  &&& {
    width:calc(100% - 40px); height:auto; margin:auto; border-collapse:collapse;
    border-collapse:collapse; border-radius:5px 5px 0 0; overflow:hidden;
    th, td { border:1px solid ${ props => props.typeColor ? props.typeColor : mainColor}; }
    th { background:${ props => props.typeColor ? props.typeColor : mainColor}; color:${ props => props.typeColor ? props.mainColor : subColor}; }
    td { color:${ props => props.typeColor ? props.typeColor : mainColor}; }
  }
`

const Row = styled.tr`
  &&& {
    width:100%; 
    th { font-size:15px; font-weight:300; padding:3px 20px; }
    td { font-size:15px; font-weight:300; padding:3px 20px; }
    .id { width:15%; text-align:left; }
    .lc { width:35%; text-align:right; }
    .kw { width:15%; text-align:center; }
    .rc { width:35%; text-align:left; }
    &&:hover { background:${rgba(mainColor,0.05)}; }
  }
`

const FreqChartStyle = styled.div`
  &&& {
    width:50%; height:500px; margin:auto; padding:20px 0; float:left;
    .custom-tooltip { width:auto; height:auto; padding:5px 10px; background:${subColor}; border-radius:5px; box-shadow:0 0 6px ${shadowColor}; 
      p { color:${ props => props.color ? props.color : mainColor}; font-size:15px; line-height:18px; text-align:left; }
    }
  }
`

export { ResultsContentStyle, ContentBlock, ExamplesStyle, Row, FreqChartStyle };
