import styled from 'styled-components'
import { rgba, lighten, darken, bgGradation } from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, lemmaColor } from 'component/common/style/Colors';
import { fadeIn } from 'component/common/style/Animations';

const ResultsLayerStyle = styled.div`
  &&& {
    width:20%; height:calc(100% + 3px); border-right:0px solid ${mainColor}; float:left; overflow:scroll;
    background:${lighten(subColor,0.05)};
  }
`

const Item = styled.div`
  &&& {
    ${fadeIn(1,0,2)}
    display:${ props => props.close ? 'none' : 'table'};
    width:100%; height:30px; margin:0; padding:3px 5px 3px ${ props => props.layerNum ? itemLeftPadding(props.layerNum) : 5}px;
    border-bottom:0px solid ${edgeColor}; border-radius:0 100px 100px 0; background:${ props => props.select ? rgba(edgeColor,0.1) : 'none'}; cursor:pointer;
    p { display:table-cell; vertical-align:middle; color:${mainColor}; font-size:15px; opacity:${ props => props.hit ? 1 : 0.3};
      .type { color:${subColor}; background:${ props => props.typeColor ? props.typeColor : mainColor}; border-radius:2px; margin:0 5px; }
    }

    // テキスト選択時の背景色と文字色 
    p::selection { background:none; color:${mainColor}; }
    p::-moz-selection { background:none; color:${mainColor}; }
    .type::selection { background:none; color:${subColor}; }
    .type::-moz-selection { background:none; color:${subColor}; }

    &:hover { background:${ props => props.select ? rgba(edgeColor,0.04) : rgba(mainColor,0.05)}; }
  }
`

// 階層の深さに応じてpadding-leftの幅を広くする
const itemLeftPadding = (layerNum) => {
  let leftMargin = 5;
  if (layerNum == 1) {
    leftMargin = 20;
  } else if (layerNum == 2) {
    leftMargin = 47;
  }
  return leftMargin;
}

export { ResultsLayerStyle, Item };
