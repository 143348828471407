import styled from 'styled-components'
import { rgba, lighten, darken, bgGradation } from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor } from 'component/common/style/Colors';
import { colorFlash } from 'component/common/style/Animations';

const SearchingWrapperStyle = styled.div`
  &&& {
    display:table;
    position:absolute; top:0; bottom:0; left:0; right:0; width:100%; height:100%;
    ${bgGradation(rgba(mainColor,0.1),rgba(edgeColor,0.05))};
    h1 { color:${mainColor}; font-size:25px; text-align:center; display:table-cell; vertical-align:middle; }
    .dot { ${colorFlash(3)} }
  }
`
export { SearchingWrapperStyle };
