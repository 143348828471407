import { white, mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, errorColor, correctColor } from 'component/common/style/Colors';
  
const animations = () => {
  return`

    @keyframes fadeIn {
      0% { opacity:0; }
      100% { opacity:1; }
    }

    @keyframes fadeOut {
      0% { opacity:1; }
      80% { opacity:1; }
      100% { opacity:0; }
    }

    @keyframes fadeOutUp {
      0% { opacity:1; transform:translateY(0); }
      100% { opacity:0; transform:translateY(20%); display:none; }
    }

    @keyframes fadeOutDown {
      0% { opacity:1; transform:translateY(0); }
      100% { opacity:0; transform:translateY(-20%); display:none; }
    }

    @keyframes fadeOutRight {
      0% { opacity:1; transform:translateX(0); }
      100% { opacity:0; transform:translateX(-20%); display:none; }
    }

    @keyframes fadeOutLeft {
      0% { opacity:1; transform:translateX(0); }
      100% { opacity:0; transform:translateX(20%); display:none; }
    }

    @keyframes hide {
      to {
        width:0; height:0; overflow:hidden;
      }
    }


    @keyframes move {
      0% { transform:translateX(20%); }
      100% { transform:translateX(0); }
    }


    @keyframes kodo {
      0% { transform:scale(0.8,0.8); }
      10% { transform:scale(1,1); }
      20% { transform:scale(0.8,0.8); }
      50% { transform:scale(1,1); }
      100% { transform:scale(1,1); }
  `
}

const fadeIn = (t=2,x=0,y=0) => {
  return`
    animation:fadeInT${t}X${x}Y${y} ${t}s ease 0s forwards;
    @keyframes fadeInT${t}X${x}Y${y} {
      0% { opacity:0; transform:translate(${x}%,${y}%); }
      100% { opacity:1; transform:translate(0,0); }
    }
  `
}

const colorFlash = (speed) => {
  return`
    animation:colorFlash${speed} ${speed}s ease infinite alternate;
    @keyframes colorFlash${speed} {
      0% { opacity:0; }
      25% { opacity:1; }
      50% { opacity:0; }
      75% { opacity:1; }
      100% { opacity:0; }
    }
  `
}

const borderFlash = (weight) => {
  return`
    animation:borderflashWeight${weight} 2s ease infinite alternate;
    @keyframes borderflashWeight${weight} {
      0% { border:${weight}px solid ${white}; }
      50% { border:${weight}px solid ${edgeColor}; }
      100% { border:${weight}px solid ${white}; }
    }
  `
}

const move = (x1,x2,y1,y2,t) => {
  return`
    animation:move-x1${x1}-x2${x2}-y1${y1}-y2${y2} ${t}s ease 0s forwards;
    @keyframes move-x1${x1}-x2${x2}-y1${y1}-y2${y2} {
      0% { transform:translate(${x1}%, ${y1}%); }
      100% { transform:translate(${x2}%, ${y2}%); }
    }
  `
}

const rotate = (r1,r2,t) => {
  return`
    animation:rotate-r1${r1}-r2${r2} ${t}s ease 0s forwards;
    @keyframes rotate-r1${r1}-r2${r2} {
      0% { transform:rotate(${r1}deg); }
      100% { transform:rotate(${r2}deg); }
    }
  `
}

const buru = (r,t) => {
  return`
    animation:buru-r${r} ${t}s linear infinite;
    @keyframes buru-r${r} { 
      0% { transform:rotate(0deg); } 
      25% { transform:rotate(${r}deg); } 
      50% { transform:rotate(0deg); } 
      75% { transform:rotate(-${r}deg); } 
      100% { transform:rotate(0deg); } 
    } 
  `
}

const kodo = (s,w,t) => {
  return`
    animation:kodo-s${s} ${t}s linear infinite, borderflash ${t}s linear infinite;
    @keyframes kodo-s${s} {
      0% { transform:scale(${s},${s}); }
      10% { transform:scale(1,1); }
      20% { transform:scale(${s},${s}); }
      50% { transform:scale(1,1); }
      100% { transform:scale(1,1); }
    }
    @keyframes kodo-borderflash {
      0% { border:${w}px solid ${edgeColor}; }
      50% { border:${w}px solid white; }
      100% { border:${w}px solid ${edgeColor}; }
    }
  `
}

export { animations, fadeIn, colorFlash, borderFlash, move, rotate, buru, kodo };
