import styled from 'styled-components'
import {rgba, lighten, darken} from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, attentionColor, errorColor, correctColor, magenta, cyan, yellow, white } from 'component/common/style/Colors';
import { Link } from 'react-router-dom';

let time = 5;
const InitializationBack = styled.div`
  &&& {
    animation:fadeOut ${time}s ease 0s forwards, hide 0s ease-in ${time}s forwards;
    position:fixed; top:0; bottom:0; left:0; right:0; z-index:1000; width:120%; height:120%; margin:auto; padding:0; background:${darken(subColor,0.2)};
  }
`

const InitializationLogo = styled.div`
  &&& {
    animation:fadeOut ${time}s ease 0s forwards, hide 0s ease-in ${time}s forwards;
    position:fixed; top:0; bottom:0; left:0; right:0; z-index:1010; width:50%; height:40px; margin:auto; 
    .logo { position:absolute; top:0; bottom:0; left:0; right:0; margin:auto; color:${lighten(mainColor,0.2)}; font-size:40px; text-align:center; animation:logo 2s linear infinite; }

    @keyframes logo {
      0% { transform:scale(0.8,0.8); }
      50% { transform:scale(1,1); }
      100% { transform:scale(0.8,0.8); }
    }
  }
`

export { InitializationBack, InitializationLogo, };
