import React from 'react';
import axios from 'axios';
import { LineChart, Line, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'; // チャート 

// module
import FreqChart from 'component/common/module/FreqChart';
import Examples from 'component/common/module/Examples';

// style
import { ResultsContentStyle, ContentBlock } from 'component/common/style/ResultsContent';
import { FreqChartStyle } from 'component/common/style/ResultsContent';
import { mainColor, subColor, edgeColor, lemmaColor, formColor, orthColor, pronColor } from 'component/common/style/Colors';

class ResultsContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      origin:this.props.origin,
      selectLayer:this.props.selectLayer, lemma:this.props.lemma, form:this.props.form, orth:this.props.orth, pron:this.props.pron, // 語彙素、語形、書字形、発音形
      gettingFreq:false, lemmaFreqBCCWJ:[], lemmaFreqCHJ:[], formFreqBCCWJ:[], formFreqCHJ:[], orthFreqBCCWJ:[], orthFreqCHJ:[], // 頻度情報
      gettingExamples:false, lemmaExamplesBCCWJ:[], formExamplesBCCWJ:[], orthExamplesBCCWJ:[], lemmaExamplesCHJ:[], formExamplesCHJ:[], orthExamplesCHJ:[], // 用例
      tooltipContent:'',
    }
  }

  componentDidMount = () => {
    if (Object.keys(this.state.lemma).length) {
      // 頻度情報を読み込むまでは空の情報をグラフに表示しておく
      this.getFreq(); // 頻度情報を取得
      this.getExamples(); // 用例を取得
    }
  }

  componentDidUpdate = () => {
    if (this.state.gettingFreq && this.state.gettingExamples) {
      this.autoScroll();
    }
  }

  autoScroll = () => {
    // 選択した階層の位置まで自動スクロール
    var contentPosition = document.getElementById('content-' + this.state.selectLayer).offsetTop; // 最上部からスクロールする要素までの距離を取得
    document.getElementById('results-content').scrollTop = contentPosition;
  }

  // 頻度情報を取得
  getFreq = () => {
    this.setState({ gettingFreq:true, });

    let lemmaID, formID, orthID;
    if (Object.keys(this.state.lemma).length) {
      lemmaID = this.state.lemma.lemmaID;
    } else {
      lemmaID = '';
    }
    if (Object.keys(this.state.form).length) {
      formID = this.state.form.formID;
    } else {
      formID = '';
    }
    if (Object.keys(this.state.orth).length) {
      orthID = this.state.orth.orthID;
    } else {
      orthID = '';
    }

    var params = { lemmaID:lemmaID, formID:formID, orthID:orthID, };
    axios
      .post(this.state.origin + 'api/get_freq/', params)
      .then(res => {
        let lemmaFreq = res.data.lemma_freq;
        let lemmaFreqs = this.splitFreq(lemmaFreq);
        let lemmaFreqBCCWJ = lemmaFreqs[0];
        let lemmaFreqCHJ = lemmaFreqs[1];

        let formFreq = res.data.form_freq;
        let formFreqs = this.splitFreq(formFreq);
        let formFreqBCCWJ = formFreqs[0];
        let formFreqCHJ = formFreqs[1];

        let orthFreq = res.data.orth_freq;
        let orthFreqs = this.splitFreq(orthFreq);
        let orthFreqBCCWJ = orthFreqs[0];
        let orthFreqCHJ = orthFreqs[1];

        this.setState({ lemmaFreqBCCWJ:lemmaFreqBCCWJ, lemmaFreqCHJ:lemmaFreqCHJ, formFreqBCCWJ:formFreqBCCWJ, formFreqCHJ:formFreqCHJ, orthFreqBCCWJ:orthFreqBCCWJ, orthFreqCHJ:orthFreqCHJ, gettingFreq:false, });
      })
      .catch(err => {
        this.setState({ gettingFreq:false, });
      });

  }


  // 取得した頻度情報の型変換をしたり、含まれていない要素があれば追加したり、並べ替えたりするためのメソッド
  splitFreq = (freqArray) => {
    const orderBCCWJ = {'PN':'出版・新聞', 'PM':'出版・雑誌', 'PB':'出版・書籍', 'LB':'図書館・書籍', 'OW':'特定目的・白書', 'OB':'特定目的・ベストセラー', 'OC':'特定目的・知恵袋', 'OY':'特定目的・ブログ', 'OL':'特定目的・法律', 'OM':'特定目的・国会会議録', 'OP':'特定目的・広報紙', 'OT':'特定目的・教科書', 'OV':'特定目的・韻文'};
    //const orderCHJ = ['奈良-万葉集', '奈良-宣命', '平安-仮名文学', '和歌集', '鎌倉-説話･随筆', '鎌倉-日記･紀行', '室町-狂言', '室町-キリシタン', '江戸-近松', '江戸-洒落本', '江戸-人情本', '明治・大正-雑誌', '明治・大正-教科書', '明治・大正-初期口語'];
    const orderCHJ = {'奈良-万葉集':'奈良-万葉集', '奈良-宣命':'奈良-宣命', '平安-仮名文学':'平安-仮名文学', '和歌集':'和歌集', '鎌倉-説話･随筆':'鎌倉-説話･随筆', '鎌倉-日記･紀行':'鎌倉-日記･紀行', '室町-狂言':'室町-狂言', '室町-キリシタン':'室町-キリシタン', '江戸-近松':'江戸-近松', '江戸-洒落本':'江戸-洒落本', '江戸-人情本':'江戸-人情本', '明治・大正-雑誌':'明治・大正-雑誌', '明治・大正-教科書':'明治・大正-教科書', '明治・大正-初期口語':'明治・大正-初期口語'};

    // wpmが文字列になっているので数値型に変換
    for (var i=0; i<freqArray.length; i++) {
      freqArray[i].frequency = Number(freqArray[i].frequency);
    }

    // wpmが文字列になっているので数値型に変換
    for (var i=0; i<freqArray.length; i++) {
      freqArray[i].wpm = Number(freqArray[i].wpm);
    }

    // freqArrayをコーパス略称別に抽出
    let freqBCCWJ = freqArray.filter(freq => freq.corpusAbbr == 'BCCWJ');
    let freqCHJ = freqArray.filter(freq => freq.corpusAbbr == 'CHJ');


    // BCCWJのサブコーパス略称で含まれていないものがあれば要素を追加する
    for (var i=0; i<Object.keys(orderBCCWJ).length; i++) {
      if (freqBCCWJ.every(freq => freq.subCorpusAbbr != Object.keys(orderBCCWJ)[i])) {
        freqBCCWJ.push({'corpusAbbr':'BCCWJ', 'subCorpusName':Object.values(orderBCCWJ)[i], 'subCorpusAbbr':Object.keys(orderBCCWJ)[i], 'lemmaID':this.state.lemma.lemmaID, 'frequency':0, 'wpm':0, });

        // wpms(下位項目の頻度情報)を追加する必要がある場合は、頻度情報オブジェクト群の中からwpmsがあるオブジェクトを取得し、各キーに対して0のバリューを挿入して、追加した空要素に突っ込む
        if (0 < freqBCCWJ.filter(freq => 0 < freq.wpm && freq.wpms).length) {
          let blankWpmsBCCWJ = {};
          let wpmsBCCWJ = { ...freqBCCWJ.filter(freq => 0 < freq.wpm)[0] };
          for (var i2=0; i2<Object.values(wpmsBCCWJ.wpms).length; i2++) {
            blankWpmsBCCWJ[Object.keys(wpmsBCCWJ.wpms)[i2]] = 0;
          }
          freqBCCWJ[freqBCCWJ.length - 1].wpms = blankWpmsBCCWJ;
        }

      }
    }


    // CHJのサブコーパス略称で含まれていないものがあれば要素を追加する
    for (var i=0; i<Object.keys(orderCHJ).length; i++) {
      if (freqCHJ.every(freq => freq.subCorpusAbbr != Object.keys(orderCHJ)[i])) {
        freqCHJ.push({'corpusAbbr':'CHJ', 'subCorpusName':Object.keys(orderCHJ)[i], 'subCorpusAbbr':Object.keys(orderCHJ)[i], 'lemmaID':this.state.lemma.lemmaID, 'frequency':0, 'wpm':0, });

        // wpms(下位項目の頻度情報)を追加する必要がある場合は、頻度情報オブジェクト群の中からwpmsがあるオブジェクトを取得し、各キーに対して0のバリューを挿入して、追加した空要素に突っ込む
        if (0 < freqCHJ.filter(freq => 0 < freq.wpm && freq.wpms).length) {
          let blankWpmsCHJ = {};
          let wpmsCHJ = { ...freqCHJ.filter(freq => 0 < freq.wpm)[0] };
          for (var i2=0; i2<Object.values(wpmsCHJ.wpms).length; i2++) {
            blankWpmsCHJ[Object.keys(wpmsCHJ.wpms)[i2]] = 0;
          }
          freqCHJ[freqCHJ.length - 1].wpms = blankWpmsCHJ;
        }

      }
    }


    // サブコーパス略称で並べ替え
    freqBCCWJ = freqBCCWJ.sort(function(x,y) {
      return Object.keys(orderBCCWJ).indexOf(x.subCorpusAbbr) - Object.keys(orderBCCWJ).indexOf(y.subCorpusAbbr);
    });
    freqCHJ = freqCHJ.sort(function(x,y) {
      return Object.keys(orderCHJ).indexOf(x.subCorpusAbbr) - Object.keys(orderCHJ).indexOf(y.subCorpusAbbr);
    });


    return [freqBCCWJ, freqCHJ];
  }


  // 用例を取得するためのメソッド
  getExamples = () => {

    this.setState({ gettingExamples:true, });

    let lemmaID, formID, orthID;
    if (Object.keys(this.state.lemma).length) {
      lemmaID = this.state.lemma.lemmaID;
    } else {
      lemmaID = '';
    }
    if (Object.keys(this.state.form).length) {
      formID = this.state.form.formID;
    } else {
      formID = '';
    }
    if (Object.keys(this.state.orth).length) {
      orthID = this.state.orth.orthID;
    } else {
      orthID = '';
    }

    var params = { lemmaID:lemmaID, formID:formID, orthID:orthID, };
    axios
      .post(this.state.origin + 'api/get_examples/', params)
      .then(res => {
        let lemmaExamplesBCCWJ = res.data.lemma_examples_bccwj;
        let formExamplesBCCWJ = res.data.form_examples_bccwj;
        let orthExamplesBCCWJ = res.data.orth_examples_bccwj;
        let lemmaExamplesCHJ = res.data.lemma_examples_chj;
        let formExamplesCHJ = res.data.form_examples_chj;
        let orthExamplesCHJ = res.data.orth_examples_chj;
        this.setState({ lemmaExamplesBCCWJ:lemmaExamplesBCCWJ, formExamplesBCCWJ:formExamplesBCCWJ, orthExamplesBCCWJ:orthExamplesBCCWJ, lemmaExamplesCHJ:lemmaExamplesCHJ, formExamplesCHJ:formExamplesCHJ, orthExamplesCHJ:orthExamplesCHJ, gettingExamples:false, });
      })
      .catch(err => {
        this.setState({ gettingExamples:false, });
      });
  }


  render() {


   {/*****************
    *                *
    *     語彙素     *
    *                *
    *****************/}

    let lemma;
    if (Object.keys(this.state.lemma).length) {

      let lemmaFreqChartBCCWJ;
      if (Object.keys(this.state.lemmaFreqBCCWJ).length) {
        lemmaFreqChartBCCWJ = ( <FreqChart type='BCCWJ' data={this.state.lemmaFreqBCCWJ} color={lemmaColor} /> );
      } else {
        lemmaFreqChartBCCWJ = ( <span></span> );
      }

      let lemmaFreqChartCHJ;
      if (Object.keys(this.state.lemmaFreqCHJ).length) {
        lemmaFreqChartCHJ = ( <FreqChart type='CHJ' data={this.state.lemmaFreqCHJ} color={lemmaColor} /> );
      } else {
        lemmaFreqChartCHJ = ( <span></span> );
      }

      let lemmaFreqCharts;
      if (this.state.gettingFreq) {
        lemmaFreqCharts = ( <p>「{this.state.lemma.lemma}」の頻度情報を読み込み中<span className='dot dot1'>.</span><span className='dot dot2'>.</span><span className='dot dot3'>.</span></p> );
      } else {
        lemmaFreqCharts = (
          <div>
            {lemmaFreqChartBCCWJ}
            {lemmaFreqChartCHJ}
          </div>
        );
      }

      let lemmaExamplesBCCWJ;
      if (this.state.selectLayer == 'lemma') {
        if (this.state.gettingExamples) {
          lemmaExamplesBCCWJ = ( <p>「{this.state.lemma.lemma}」の用例(BCCWJ)を読み込み中<span className='dot dot1'>.</span><span className='dot dot2'>.</span><span className='dot dot3'>.</span></p> );
        } else {
          if (Object.keys(this.state.lemmaExamplesBCCWJ).length) {
            if (0 < this.state.lemmaExamplesBCCWJ.rows.length) {
              lemmaExamplesBCCWJ = ( <Examples type='BCCWJ' examples={this.state.lemmaExamplesBCCWJ} color={lemmaColor} /> );
            } else {
              lemmaExamplesBCCWJ = ( <p>「{this.state.lemma.lemma}」の用例(BCCWJ)が見つかりませんでした。</p> );
            }
          } else {
            lemmaExamplesBCCWJ = ( <p>「{this.state.lemma.lemma}」の用例(BCCWJ)が見つかりませんでした。</p> );
          }
        }
      } else {
        lemmaExamplesBCCWJ = ( <span></span> );
      }

      let lemmaExamplesCHJ;
      if (this.state.selectLayer == 'lemma') {
        if (this.state.gettingExamples) {
          lemmaExamplesCHJ = ( <p>「{this.state.lemma.lemma}」の用例(CHJ)を読み込み中<span className='dot dot1'>.</span><span className='dot dot2'>.</span><span className='dot dot3'>.</span></p> );
        } else {
          if (Object.keys(this.state.lemmaExamplesCHJ).length) {
            if (0 < this.state.lemmaExamplesCHJ.rows.length) {
              lemmaExamplesCHJ = ( <Examples type='CHJ' examples={this.state.lemmaExamplesCHJ} color={lemmaColor} /> );
            } else {
              lemmaExamplesCHJ = ( <p>「{this.state.lemma.lemma}」の用例(CHJ)が見つかりませんでした。</p> );
            }
          } else {
            lemmaExamplesCHJ = ( <p>「{this.state.lemma.lemma}」の用例(CHJ)が見つかりませんでした。</p> );
          }
        }
      } else {
        lemmaExamplesCHJ = ( <span></span> );
      }

      lemma = (
        <ContentBlock typeColor={lemmaColor}>
          <h2 id='content-lemma'>語彙素</h2>
          <div>
            <p>
              <label>語彙素読み</label><span className='large'>{this.state.lemma.reading}</span><br/>
              <label>語彙素</label><span className='large'>{this.state.lemma.lemma}</span><br/>
              <label>語彙素ID</label>{this.state.lemma.lemmaID}、
              <label>類</label>{this.state.lemma.wordType}、
              <label>語義</label>{this.state.lemma.meaning}、
              <label>語種</label>{this.state.lemma.goshu}、
              <label>自</label>{this.state.lemma.since}、
              <label>至</label>{this.state.lemma.until}
            </p>
          </div>
          {lemmaFreqCharts}
          {lemmaExamplesBCCWJ}
          {lemmaExamplesCHJ}
        </ContentBlock>
      );
    } else {
      lemma = ( <span></span> );
    }



   {/***************
    *              *
    *     語形     *
    *              *
    ***************/}

    let form;
    if (Object.keys(this.state.form).length) {

      let formFreqChartBCCWJ;
      if (Object.keys(this.state.formFreqBCCWJ).length) {
        formFreqChartBCCWJ = ( <FreqChart type='BCCWJ' data={this.state.formFreqBCCWJ} color={formColor} /> );
      } else {
        formFreqChartBCCWJ = ( <span></span> );
      }

      let formFreqChartCHJ;
      if (Object.keys(this.state.formFreqCHJ).length) {
        formFreqChartCHJ = ( <FreqChart type='CHJ' data={this.state.formFreqCHJ} color={formColor} /> );
      } else {
        formFreqChartCHJ = ( <span></span> );
      }

      let formFreqCharts;
      if (this.state.gettingFreq) {
        formFreqCharts = ( <p>「{this.state.form.formBase}」の頻度情報を読み込み中<span className='dot dot1'>.</span><span className='dot dot2'>.</span><span className='dot dot3'>.</span></p> );
      } else {
        formFreqCharts = (
          <div>
            {formFreqChartBCCWJ}
            {formFreqChartCHJ}
          </div>
        );
      }

      let formExamplesBCCWJ;
      if (this.state.selectLayer == 'form') {
        if (this.state.gettingExamples) {
          formExamplesBCCWJ = ( <p>「{this.state.form.formBase}」の用例(BCCWJ)を読み込み中<span className='dot dot1'>.</span><span className='dot dot2'>.</span><span className='dot dot3'>.</span></p> );
        } else {
          if (Object.keys(this.state.formExamplesBCCWJ).length) {
            if (0 < this.state.formExamplesBCCWJ.rows.length) {
              formExamplesBCCWJ = ( <Examples type='BCCWJ' examples={this.state.formExamplesBCCWJ} color={formColor} /> );
            } else {
              formExamplesBCCWJ = ( <p>「{this.state.form.formBase}」の用例(BCCWJ)が見つかりませんでした。</p> );
            }
          } else {
            formExamplesBCCWJ = ( <p>「{this.state.form.formBase}」の用例(BCCWJ)が見つかりませんでした。</p> );
          }
        }
      } else {
        formExamplesBCCWJ = ( <span></span> );
      }

      let formExamplesCHJ;
      if (this.state.selectLayer == 'form') {
        if (this.state.gettingExamples) {
          formExamplesCHJ = ( <p>「{this.state.form.formBase}」の用例(CHJ)を読み込み中<span className='dot dot1'>.</span><span className='dot dot2'>.</span><span className='dot dot3'>.</span></p> );
        } else {
          if (Object.keys(this.state.formExamplesCHJ).length) {
            if (0 < this.state.formExamplesCHJ.rows.length) {
              formExamplesCHJ = ( <Examples type='CHJ' examples={this.state.formExamplesCHJ} color={formColor} /> );
            } else {
              formExamplesCHJ = ( <p>「{this.state.form.formBase}」の用例(CHJ)が見つかりませんでした。</p> );
            }
          } else {
            formExamplesCHJ = ( <p>「{this.state.form.formBase}」の用例(CHJ)が見つかりませんでした。</p> );
          }
        }
      } else {
        formExamplesCHJ = ( <span></span> );
      }

      form = (
        <ContentBlock typeColor={formColor}>
          <h2 id='content-form'>語形</h2>
          <div>
            <p>
              <label>語形</label><span className='large'>{this.state.form.formBase}</span><br/>
              <label>品詞</label><span className='large'>{this.state.form.pos}</span><br/>
              <label>語形ID</label>{this.state.form.formID}、
              <label>語頭変化型</label>{this.state.form.iType}、
              <label>語末変化型</label>{this.state.form.fType}、
              <label>代表性</label>{this.state.form.representative}、
              <label>入力活用形</label>{this.state.form.cTypeMain}、
              <label>活用形細分類</label>{this.state.form.cTypeSub}、
              <label>自</label>{this.state.form.since}、
              <label>至</label>{this.state.form.until}
            </p>
          </div>
          {formFreqCharts}
          {formExamplesBCCWJ}
          {formExamplesCHJ}
        </ContentBlock>
      );
    } else {
      form = ( <span></span> );
    }



   {/*****************
    *                *
    *     書字形     *
    *                *
    *****************/}

    let orth;
    if (Object.keys(this.state.orth).length) {

      let orthFreqChartBCCWJ;
      if (Object.keys(this.state.orthFreqBCCWJ).length) {
        orthFreqChartBCCWJ = ( <FreqChart type='BCCWJ' data={this.state.orthFreqBCCWJ} color={orthColor} /> );
      } else {
        orthFreqChartBCCWJ = ( <span></span> );
      }

      let orthFreqChartCHJ;
      if (Object.keys(this.state.orthFreqCHJ).length) {
        orthFreqChartCHJ = ( <FreqChart type='CHJ' data={this.state.orthFreqCHJ} color={orthColor} /> );
      } else {
        orthFreqChartCHJ = ( <span></span> );
      }

      let orthFreqCharts;
      if (this.state.gettingFreq) {
        orthFreqCharts = ( <p>「{this.state.orth.orthBase}」の頻度情報を読み込み中<span className='dot dot1'>.</span><span className='dot dot2'>.</span><span className='dot dot3'>.</span></p> );
      } else {
        orthFreqCharts = (
          <div>
            {orthFreqChartBCCWJ}
            {orthFreqChartCHJ}
          </div>
        );
      }

      let orthExamplesBCCWJ;
      if (this.state.selectLayer == 'orth') {
        if (this.state.gettingExamples) {
          orthExamplesBCCWJ = ( <p>「{this.state.orth.orthBase}」の用例(BCCWJ)を読み込み中<span className='dot dot1'>.</span><span className='dot dot2'>.</span><span className='dot dot3'>.</span></p> );
        } else {
          if (Object.keys(this.state.orthExamplesBCCWJ).length) {
            if (0 < this.state.orthExamplesBCCWJ.rows.length) {
              orthExamplesBCCWJ = ( <Examples type='BCCWJ' examples={this.state.orthExamplesBCCWJ} color={orthColor} /> );
            } else {
              orthExamplesBCCWJ = ( <p>「{this.state.orth.orthBase}」の用例(BCCWJ)が見つかりませんでした。</p> );
            }
          } else {
            orthExamplesBCCWJ = ( <p>「{this.state.orth.orthBase}」の用例(BCCWJ)が見つかりませんでした。</p> );
          }
        }
      } else {
        orthExamplesBCCWJ = ( <span></span> );
      }

      let orthExamplesCHJ;
      if (this.state.selectLayer == 'orth') {
        if (this.state.gettingExamples) {
          orthExamplesCHJ = ( <p>「{this.state.orth.orthBase}」の用例(CHJ)を読み込み中<span className='dot dot1'>.</span><span className='dot dot2'>.</span><span className='dot dot3'>.</span></p> );
        } else {
          if (Object.keys(this.state.orthExamplesCHJ).length) {
            if (0 < this.state.orthExamplesCHJ.rows.length) {
              orthExamplesCHJ = ( <Examples type='CHJ' examples={this.state.orthExamplesCHJ} color={orthColor} /> );
            } else {
              orthExamplesCHJ = ( <p>「{this.state.orth.orthBase}」の用例(CHJ)が見つかりませんでした。</p> );
            }
          } else {
            orthExamplesCHJ = ( <p>「{this.state.orth.orthBase}」の用例(CHJ)が見つかりませんでした。</p> );
          }
        }
      } else {
        orthExamplesCHJ = ( <span></span> );
      }


      orth = (
        <ContentBlock typeColor={orthColor}>
          <h2 id='content-orth'>書字形</h2>
          <div>
            <p>
              <label>書字形</label><span className='large'>{this.state.orth.orthBase}</span><br/>
              <label>仮名形</label><span className='large'>{this.state.orth.kanaBase}</span><br/>
              <label>書字形ID</label>{this.state.orth.orthID}、
              <label>代表性</label>{this.state.orth.representative}、
              <label>自</label>{this.state.orth.since}、
              <label>至</label>{this.state.orth.until}
            </p>
          </div>
          {orthFreqCharts}
          {orthExamplesBCCWJ}
          {orthExamplesCHJ}
        </ContentBlock>
      );
    } else {
      orth = ( <span></span> );
    }



   {/*****************
    *                *
    *     発音形     *
    *                *
    *****************/}

    let pron;
    if (Object.keys(this.state.pron).length) {
      pron = (
        <ContentBlock typeColor={pronColor}>
          <h2 id='content-pron'>発音形</h2>
          <div>
            <p>
              <label>発音形ID</label>{this.state.pron.pronID}、
              <label>発音形</label>{this.state.pron.pronBase}、
              <label>代表性</label>{this.state.pron.representative}
            </p>
          </div>
        </ContentBlock>
      );
    } else {
      pron = ( <span></span> );
    }


    return (
      <ResultsContentStyle id='results-content'>
        {lemma} {/* 語彙素 */}
        {form} {/* 語形 */}
        {orth} {/* 書字形 */}
        {pron} {/* 発音形 */}
      </ResultsContentStyle>
    );


  }
}

export default ResultsContent;
