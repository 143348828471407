import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { LineChart, Line, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'; // チャート 

import { ExamplesStyle, Row } from 'component/common/style/ResultsContent';

class Examples extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      type:this.props.type,
      examples:this.props.examples,
      color:this.props.color,
    }
  }

  render() {

    let rows = this.state.examples.rows.map((row,index) => {

      if (index < 10) {
        let lcontext;
        if (0 < row.lcontext.length) { 
          lcontext = row.lcontext.map((lc,index) => {
            return ( <span>{lc.orth_token}</span> );
          })
        }

        let keyword;
        if (0 < row.keyword.length) { 
          keyword = row.keyword.map((kw,index) => {
            return ( <span>{kw.orth_token}</span> );
          })
        }

        let rcontext;
        if (0 < row.rcontext.length) { 
          rcontext = row.rcontext.map((rc,index) => {
            return ( <span>{rc.orth_token}</span> );
          })
        }

        return (
          <Row>
            <td className='id'>{row.sample_id}</td>
            <td className='lc'>{lcontext}</td>
            <td className='kw'>{keyword}</td>
            <td className='rc'>{rcontext}</td>
          </Row>
        );

      }

    });



    return (
      <div>
        <h3 style={{'color':this.state.color}}>用例検索({this.state.type})</h3>
        <ExamplesStyle typeColor={this.state.color}>
          <Row><th className='id'>サンプルID</th><th className='lc'>前文脈</th><th className='kw'>キー</th><th className='rc'>後文脈</th></Row>
          {rows}
        </ExamplesStyle>
        <a href={'https://chunagon.ninjal.ac.jp/' + (this.state.type == 'BCCWJ' ? 'bccwj-nt' : 'chj') + '/search?log&id=' + this.state.examples.search_log_id} target='_blank'>用例検索結果(外部システム)へのリンク</a>
        {/*<a href={'https://chunagon.ninjal.ac.jp/bccwj-nt/search?log&id=' + this.state.examples.search_log_id} target='_blank'>用例検索結果(外部システム)へのリンク</a>*/}
      </div>
    );


  }
}

export default Examples;
