import styled from 'styled-components'
import { rgba, lighten, darken, bgGradation } from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor } from 'component/common/style/Colors';
import { colorFlash } from 'component/common/style/Animations';

const Results = styled.div`
  &&& {
    width:100vw; height:calc(100vh - 45px); margin:0; padding:0; position:relative; overflow:hidden;
  }
`
export { Results };
