let white = '#c8c8c8';
let black = '#2b2b2b';
let red = '#d7003a'; 
let blue = '#2ca9e1';
let yellow = '#f8b500';
let cyan = '#00FFFF';
let magenta = '#FF00FF';
let brown = '#954535';
let navy = '#0020C2';
let purple = '#8D38C9';
let green = '#3EA055';

let mainColor = white; // 主に文字色に使われる
let subColor = black; // 主に背景色に使われる
let edgeColor = cyan; // 主にロゴや選択項目など、目立たせたいものに差し色として使われる

let linkColor = white; // リンクの文字色に使われる
let shadowColor = '#16160e'; // 影の色に使われる
let errorColor = red; // エラー時の色に使われる
let attentionColor = red; // 注意喚起時の色に使われる
let correctColor = green; // 処理完了時の色に使われる

let lemmaColor = '#4285F4'; // 語彙素のカラー。google blue 
let formColor = '#DB4437'; // 語形のカラー。google red
let orthColor = '#F4B400'; // 書字形のカラー。google yellow
let pronColor = '#0F9D58'; // 発音形のカラー。google green

export {
  white, black, red, blue, yellow, cyan, magenta, brown, navy, purple, green,
  mainColor, subColor, edgeColor, linkColor, shadowColor, errorColor, attentionColor, correctColor,
  lemmaColor, formColor, orthColor, pronColor
};
