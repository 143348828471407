import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import styled from 'styled-components'


const rgba = (hex, amount = 1) => {
  let color = getColor(hex, 0);
  const temp = 'rgba(' + color.r + ',' + color.b + ',' + color.g + ',' + amount + ')'
  return temp
}

// lighten //
const lighten = (hex, amount = 1) => {
  const amt = amount * 100;
  const temp = getColor(hex, amt).temp;
  return temp
}

// darken //
const darken = (hex, amount = 1) => {
  const amt = -(amount * 100);
  const temp = getColor(hex, amt).temp;
  return temp
}

// 共通 //
const getColor = (hex, amt) => {
  let usePound = false
  if (hex[0] == "#") {
    hex = hex.slice(1)
    usePound = true
  }
  const num = parseInt(hex, 16)
  let r = (num >> 16) + amt
  if (r > 255) {
    r = 255
  } else if (r < 0) {
    r = 0
  }
  let b = ((num >> 8) & 0x00ff) + amt
  if (b > 255) {
    b = 255
  } else if (b < 0) {
    b = 0
  }
  let g = (num & 0x0000ff) + amt
  if (g > 255) {
    g = 255
  } else if (g < 0) {
    g = 0
  }
  let temp = (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  return {temp:temp, r:r, b:b, g:g}
}

const bgGradation = (gc1,gc2) => {
  return`
    background:-moz-linear-gradient(65deg, ${gc1}, ${gc2});
    background:-webkit-linear-gradient(65deg, ${gc1}, ${gc2});
    background:linear-gradient(65deg, ${gc1}, ${gc2});
  `
}

export { rgba, lighten, darken, bgGradation };
