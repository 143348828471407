import React from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'; // アニメーションを行うためのもの
import * as escape from 'escape-html';

// style
import { Results } from 'component/common/style/Results';

// module
import SearchBlock from 'component/common/module/SearchBlock'; // 検索ボックス
import ResultsLayer from 'component/common/module/ResultsLayer'; // 【検索結果】階層構造の表示
import ResultsContent from 'component/common/module/ResultsContent'; // 【検索結果】形態素情報の表示、頻度情報の可視化、簡素な用例表示（外部システム連携）
import SearchingWrapper from 'component/common/module/SearchingWrapper'; // 検索時に検索中と表示するためのモジュール

class Home extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      origin:this.props.origin,
      searching:false, lemmas:[], forms:[], orths:[], prons:[],
      showing:true, selectLayer:'', lemma:[], form:[], orth:[], pron:[],
      showSearchBlock:true, searchTarget:'lemma', searchWord:'一寸',
    };
  }

  componentDidMount = () => {
    document.getElementById('search-word').focus();
    axios
      .get(this.state.origin + 'api/dump_absorption/')
      .then(res => {
        console.log('absorption done');
      })
      .catch(err => {console.log('absorption fales');});
  }


  search = (event, searchTarget, searchWord) => {
    event.preventDefault();
    if (searchWord != '') {

      return new Promise((resolve, reject) => {
        this.setState({ showSearchBlock:false, });
        resolve();
      }).then(() => {
        return new Promise( (resolve, reject) => {

          this.setState({ searching:true, showSearchBlock:true, searchTarget:searchTarget, searchWord:searchWord, });
          this.searching(true);
          var params = { search_target:searchTarget, search_word:searchWord, };
          axios
            .post(this.state.origin + 'api/search/', params)
            .then(res => {
              let lemmas = res.data.lemmas;
              for (var lemmaIndex=0; lemmaIndex<lemmas.length; lemmaIndex++) {
                lemmas[lemmaIndex].acordion = true; // 下位項目を開いている状態の場合はtrue、そうでない場合はfalse
                lemmas[lemmaIndex].close = false; // ResultsLayeredで表示する場合はfalse、非表示の場合はtrue
                lemmas[lemmaIndex].hit = true; // 検索条件にヒットしたか
              }

              let forms = res.data.forms;
              for (var formIndex=0; formIndex<forms.length; formIndex++) {
                forms[formIndex].acordion = true; // 下位項目を開いている状態の場合はtrue、そうでない場合はfalse
                forms[formIndex].close = false; // ResultsLayeredで表示する場合はfalse、非表示の場合はtrue
                forms[formIndex].hit = true; // 検索条件にヒットしたか
              }

              let orths = res.data.orths;
              for (var orthIndex=0; orthIndex<orths.length; orthIndex++) {
                orths[orthIndex].close = false; // ResultsLayeredで表示する場合はfalse、非表示の場合はtrue
                orths[orthIndex].hit = true; // 検索条件にヒットしたか
              }

              let prons = res.data.prons;
              for (var pronIndex=0; pronIndex<prons.length; pronIndex++) {
                prons[pronIndex].close = false; // ResultsLayeredで表示する場合はfalse、非表示の場合はtrue
                prons[pronIndex].hit = true; // 検索条件にヒットしたか
              }

              //this.props.getData(lemmas, forms, orths, prons);
              resolve({'lemmas':lemmas, 'forms':forms, 'orths':orths, 'prons':prons, });
            })
            .catch(err => {
              reject();
            });

        });

      }).then((p) => {
        var lemmas = p.lemmas;
        var forms = p.forms;
        var orths = p.orths;
        var prons = p.prons;
        return new Promise( (resolve, reject) => {

          for (var i=0; i<lemmas.length; i++) {
            var params = { search_target:'lemma', search_word:lemmas[i].lemma, };
            axios
              .post(this.state.origin + 'api/search/', params)
              .then(res => {
                let allLemmas = res.data.lemmas;
                for (var lemmaIndex=0; lemmaIndex<allLemmas.length; lemmaIndex++) {
                  if (lemmas.some( lemma => lemma.lemmaID == allLemmas[lemmaIndex].lemmaID) == false) {
                    allLemmas[lemmaIndex].acordion = false; // 下位項目を開いている状態の場合はtrue、そうでない場合はfalse
                    allLemmas[lemmaIndex].close = false; // ResultsLayeredで表示する場合はfalse、非表示の場合はtrue
                    allLemmas[lemmaIndex].hit = false; // 実際の検索にはヒットしていないのでfalse
                    lemmas.push(allLemmas[lemmaIndex]);
                  }
                }

                let allForms = res.data.forms;
                for (var formIndex=0; formIndex<allForms.length; formIndex++) {
                  if (forms.some( form => form.formID == allForms[formIndex].formID) == false) {
                    allForms[formIndex].acordion = false; // 下位項目を開いている状態の場合はtrue、そうでない場合はfalse

                    // 検索にヒットした語彙素と兄弟要素であれば表示。ちがうのであれば非表示
                    if (lemmas.some( lemma => lemma.hit && lemma.lemmaID == allForms[formIndex].lemma)) {
                      allForms[formIndex].close = false; // ResultsLayeredで表示する場合はfalse、非表示の場合はtrue
                    } else {
                      allForms[formIndex].close = true; // ResultsLayeredで表示する場合はfalse、非表示の場合はtrue
                    }

                    allForms[formIndex].hit = false; // 実際の検索にはヒットしていないのでfalse
                    forms.push(allForms[formIndex]);
                  }
                }

                let allOrths = res.data.orths;
                for (var orthIndex=0; orthIndex<allOrths.length; orthIndex++) {
                  if (orths.some( orth => orth.orthID == allOrths[orthIndex].orthID) == false) {
                    allOrths[orthIndex].acordion = false; // 下位項目を開いている状態の場合はtrue、そうでない場合はfalse

                    // 検索にヒットした書字形と兄弟要素であれば表示。ちがうのであれば非表示
                    if (forms.some( form => form.hit && form.formID == allOrths[orthIndex].form)) {
                      allOrths[orthIndex].close = false; // ResultsLayeredで表示する場合はfalse、非表示の場合はtrue
                    } else {
                      allOrths[orthIndex].close = true; // ResultsLayeredで表示する場合はfalse、非表示の場合はtrue
                    }

                    allOrths[orthIndex].hit = false; // 実際の検索にはヒットしていないのでfalse
                    orths.push(allOrths[orthIndex]);
                  }
                }

                let allProns = res.data.prons;
                for (var pronIndex=0; pronIndex<allProns.length; pronIndex++) {
                  if (prons.some( pron => pron.pronID == allProns[pronIndex].pronID) == false) {
                    allProns[pronIndex].acordion = false; // 下位項目を開いている状態の場合はtrue、そうでない場合はfalse

                    // 検索にヒットした発音形と兄弟要素であれば表示。ちがうのであれば非表示
                    if (forms.some( form => form.hit && form.formID == allProns[pronIndex].form)) {
                      allProns[pronIndex].close = false; // ResultsLayeredで表示する場合はfalse、非表示の場合はtrue
                    } else {
                      allProns[pronIndex].close = true; // ResultsLayeredで表示する場合はfalse、非表示の場合はtrue
                    }

                    allProns[pronIndex].hit = false; // 実際の検索にはヒットしていないのでfalse
                    prons.push(allProns[pronIndex]);
                  }
                }

                this.getData(lemmas, forms, orths, prons);
                resolve();
              })
              .catch(err => {
                reject();
              });


          }

        });
      }).catch(() => {
        // エラー発生
       return new Promise( (resolve, reject) => {
         this.searching(false);
         resolve();
      });
    });


    } else {
      this.searching(false);
    }
  }






  searching = (flag) => {
    return new Promise((resolve, reject) => {
      this.setState({ showing:false, });
      resolve();
    }).then(() => {
      return new Promise( (resolve, reject) => {
        //this.setState({ searching:flag, showing:true, lemma:[], form:[], orth:[], pron:[], });
        this.setState({ searching:flag, lemmas:[], forms:[], orths:[], prons:[], showing:true, lemma:[], form:[], orth:[], pron:[], });
      });
    });
  }

  getData = (lemmas, forms, orths, prons) => {
    return new Promise((resolve, reject) => {
      this.setState({ searching:true, });
      resolve();
    }).then(() => {
      return new Promise( (resolve, reject) => {
        this.setState({ lemmas:lemmas, forms:forms, orths:orths, prons:prons, searching:false, });
      });
    })
  }

  showData = (selectLayer, lemma, form, orth, pron) => {

    return new Promise((resolve, reject) => {
      this.setState({ showing:false, });
      resolve();
    }).then(() => {
      return new Promise( (resolve, reject) => {
        this.setState({ selectLayer, lemma:lemma, form:form, orth:orth, pron:pron, showing:true, });
      });
    })

  }

  render() {


    let searchBlock;
    if (this.state.showSearchBlock) {
      searchBlock = ( <SearchBlock origin={this.state.origin} searching={this.searching} search={this.search} getData={this.getData} searchTarget={this.state.searchTarget} searchWord={this.state.searchWord} /> );
    } else {
      searchBlock = ( <span></span> );
    }

    let resultsLayer;
    if (this.state.searching == false) {
      resultsLayer = ( <ResultsLayer origin={this.state.origin} search={this.search} showData={this.showData} lemmas={this.state.lemmas} forms={this.state.forms} orths={this.state.orths} prons={this.state.prons} /> );
    } else {
      resultsLayer = ( <span></span> );
    }

    let resultsContent;
    if (this.state.showing) {
      resultsContent = ( <ResultsContent origin={this.state.origin} selectLayer={this.state.selectLayer} lemma={this.state.lemma} form={this.state.form} orth={this.state.orth} pron={this.state.pron} /> );
    } else {
      resultsContent = ( <span></span> );
    }

    let searchingWrapper;
    if (this.state.searching) {
      searchingWrapper = ( <SearchingWrapper/> );
    } else {
      searchingWrapper = ( <span></span> );
    }


    return (
      <div>

        {/* 語彙素読み、語彙素、語形、書字形のいずれかを指定した検索 */}
        {searchBlock}

        <Results>
          {searchingWrapper} {/* 検索時に検索中と表示させるためのモジュール */}
          {resultsLayer} {/* 【検索結果】階層構造の表示 */}
          {resultsContent} {/* 【検索結果】詳細 */}
        </Results>

      </div>
    );
  }

}
export default Home;
