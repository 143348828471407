import React from 'react';
import axios from 'axios';
import { LineChart, Line, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'; // チャート 

import { FreqChartStyle } from 'component/common/style/ResultsContent';
import { rgba, lighten, darken, bgGradation } from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, lemmaColor, formColor, orthColor, pronColor } from 'component/common/style/Colors';

class FreqChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      type:this.props.type,
      data:this.props.data,
      color:this.props.color,
    }
  }

  componentDidMount = () => {
  }

  customTooltipOnYourLine(e) {
    if (e.active && e.payload!=null && e.payload[0]!=null) {

      let wpms = [];
      if (e.payload[0].payload['wpms']) {
        for (var i=0; i<Object.keys(e.payload[0].payload['wpms']).length; i++) {
          if (0 < Object.values(e.payload[0].payload['wpms'])[i]) {
            wpms.push( <span>　{Object.keys(e.payload[0].payload['wpms'])[i]}: {Object.values(e.payload[0].payload['wpms'])[i]}<br/></span> );
          }
        }
      } else {
        wpms.push( <span></span> );
      }

      return (
        <div className='custom-tooltip'>
          <p>{e.payload[0].payload['subCorpusName']}<br/>
             PMW: {e.payload[0].payload['wpm']}<br/>
             {wpms}
             粗頻度: {e.payload[0].payload['frequency']}</p>
        </div>
      );
    } else{
      return '';
    }
  }

  render() {

    const bars = [];
    if (this.state.data[0].wpms) {
      {/* 積み上げ棒グラフで下位項目の各頻度情報を表示する場合 */}
      let colors = ['#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#f1c40f', '#e67e22', '#e74c3c', '#ecf0f1', '#16a085', '#27ae60', '#2980b9', '#8e44ad', '#f39c12', '#d35400', '#c0392b', '#bdc3c7'];
      for (var i=0; i<Object.keys(this.state.data[0].wpms).length; i++) {
        bars.push( <Bar dataKey={'wpms.' + Object.keys(this.state.data[0].wpms)[i]} stackId='a' fill={colors[i]} barSize={10} /> );
      };
    } else {
      bars.push( <Bar dataKey='wpm' fill={this.state.color} barSize={10} /> );
    }


    return (
      <FreqChartStyle color={this.state.color}>
        <h3 style={{'color':this.state.color}}>頻度情報({this.state.type})</h3>
        <ResponsiveContainer>
          <BarChart data={this.state.data} margin={{ top:5, right:20, bottom:5, left:0 }}>
            {bars}
            <CartesianGrid stroke={this.state.color} strokeDasharray='5 5' style={{opacity:1}} />
            <XAxis dataKey='subCorpusAbbr' stroke={this.state.color} style={{opacity:1}} interval={0} angle={-65} textAnchor='end' height={200} />
            <YAxis stroke={this.state.color} style={{opacity:1}} interval={0} />
            <Tooltip content={this.customTooltipOnYourLine} cursor={{stroke:'none', fill:rgba(mainColor,0.05)}} />
            {/*<Legend />*/}
          </BarChart>
        </ResponsiveContainer>
      </FreqChartStyle>
    );


  }
}

export default FreqChart;
