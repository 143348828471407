import React, { Component } from 'react';
import { Router, Route, Link, Switch, Redirect } from 'react-router-dom'; // SPA化するためのもの
import History from 'History'; // 関数内でページ遷移を行わせるためのもの

// page
import Home from 'component/home/Home'; // ホーム

// module
import { InitializationBack, InitializationLogo } from 'component/common/style/Init';

// style
import { GlobalStyle } from 'component/common/style/GlobalStyle'; // サイト全体に適応されるスタイル


class App extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      origin: 'https://' + window.location.hostname + '/', // API
    };
  }

  componentDidMount() {
  }

  render(){
    return(
      <div>
        <Router history={History}>
          <GlobalStyle />
          <InitializationBack></InitializationBack>
          <InitializationLogo><h1 className='logo'>Udex</h1></InitializationLogo>
          <Switch>

            {/* ホーム */}
            <Route exact path='/'>
              <Home origin={this.state.origin}/>
            </Route>

          </Switch>
        </Router>
      </div>
    )
  }
}

export default App;
