import styled from 'styled-components'
import { rgba, lighten, darken, bgGradation } from 'component/common/style/ColorFunc';
import { mainColor, subColor, edgeColor, linkColor, shadowColor, lemmaColor, formColor, orthColor } from 'component/common/style/Colors';

const SearchBlockStyle = styled.div`
  &&& {
    width:100vw; height:45px; margin:0 auto; padding:5px;
    background:${darken(subColor,0.05)};

    .searchbox { width:auto; height:35px; margin:0; padding:0;
      input[type='text'] { width:200px; height:35px; margin:0; padding:0 10px; font-size:18px; color:${mainColor}; background:${rgba(subColor,0)};
                           border:0.5px solid ${rgba(mainColor,0.6)}; border-radius:10000px 0 0 10000px; display:inline-block; float:left; }
      input[type='text']:focus { outline:0; border:1px solid ${rgba(mainColor,1)}; }
      input[type='text']:focus + .target { opacity:1; }
      .search-btn { width:100px; height:35px; margin:0; padding:0; border:none; color:${mainColor};
                    background:${mainColor}; display:inline-block; cursor:pointer; opacity:0.3; }
      .reading { background:${darken(lemmaColor,0.1)}; }
      .lemma { background:${lemmaColor}; }
      .formBase { background:${formColor}; }
      .orthBase { background:${darken(orthColor,0.4)}; }
      .target { opacity:0.9; border-bottom:0px solid ${edgeColor}; }
      .search-btn:hover { opacity:1; }
      .search-btn:focus { outline:0; }
    }

  }
`

const SearchBlockOneStyle = styled.div`
  &&& {
    width:100vw; height:150px; margin:0 auto; padding:40px;
    ${bgGradation(subColor,rgba(edgeColor,0.08))};

    .searchbox { width:40%; height:35px; margin:0 auto; padding:0; border-radius:10000px;
      input[type='text'] { width:90%; height:35px; margin:0; padding:0 10px; font-size:18px; color:${mainColor}; background:${rgba(subColor,0)};
                           border:0.5px solid ${rgba(mainColor,0.6)}; border-radius:10000px 0 0 10000px; display:inline-block; float:left; }
      input[type='text']:focus { outline:0; border:1px solid ${rgba(mainColor,1)}; }
      input[type='text']:focus + .search-btn { opacity:1; }
      .search-btn { width:10%; height:35px; margin:0; padding:0; border:none; color:${subColor}; background:${mainColor};
                    border-radius:0 10000px 10000px 0; display:inline-block; cursor:pointer; float:right; opacity:0.6; }
      .search-btn:hover { opacity:1; }
      .search-btn:focus { outline:0; }
    }

  }
`

const CheckBoxFieldWrapper = styled.div`
  &&& {
    width:100%; height:auto; padding:10px; text-align:center;
  }
`
const CheckBoxField = styled.div`
  &&& {
    width:auto; height:auto; margin:auto; display:inline-block;
  }
`

const Item = styled.div`
  &&& {
    width:auto; height:auto; margin:0; padding:10px; display:inline-block;
    label { color:${mainColor}; font-size:15px; line-height:25px; }
  }
`

const CheckBox = styled.div`
  &&& {
    width:15px; height:15px; margin-right:5px; border-radius:100px; display:inline-block; cursor:pointer;
    background:${ props => props.check ? edgeColor : 'none'};
    border:0.5px solid ${ props => props.check ? edgeColor : rgba(mainColor,0.7)};
  }
`

export { SearchBlockStyle, CheckBoxFieldWrapper, CheckBoxField, Item, CheckBox };
