import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types'; // propのタイプを定義するためのもの

// style
import { SearchBlockStyle, SearchBlockOneStyle, CheckBoxFieldWrapper, CheckBoxField, Item, CheckBox } from 'component/common/style/SearchBlock';

class SearchBlock extends React.Component {

  static propTypes = {
    searching: PropTypes.func,
    search: PropTypes.func,
    getData: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchTarget:this.props.searchTarget, searchWord:this.props.searchWord,
    };
  }


  componentDidMount = () => {
    //document.getElementById('search-word').focus();
    document.addEventListener('keydown', this.keydown, false);
  }

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.keydown, false);
  }

  keydown = (event) => {

    let searchTarget = '';
    if (event.ctrlKey && event.shiftKey && event.keyCode == '76') {
      // 「Ctrl + Shift + l」を押下した場合、lemmaで検索
      searchTarget = 'lemma';
    } else if (event.ctrlKey && event.shiftKey && event.keyCode == '82') {
      // 「Ctrl + Shift + r」を押下した場合、readingで検索
      searchTarget = 'reading';
    } else if (event.ctrlKey && event.shiftKey && event.keyCode == '70') {
      // 「Ctrl + Shift + f」を押下した場合、formBaseで検索
      searchTarget = 'formBase';
    } else if (event.ctrlKey && event.shiftKey && event.keyCode == '79') {
      // 「Ctrl + Shift + o」を押下した場合、orthBaseで検索
      searchTarget = 'orthBase';
    } else if (event.ctrlKey && event.shiftKey && event.keyCode == '73') {
      // 「Ctrl + Shift + i」を押下した場合、検索ワード入力欄をフォーカス
      event.preventDefault();
      document.getElementById('search-word').focus();
    } else if (event.keyCode == '13' && document.activeElement.id == 'search-word') {
      // search-wordをフォーカス中にEnterを押下した場合、選択中のsearchTargetで検索
      searchTarget = this.state.searchTarget;
    } else if (event.keyCode == '27' && document.activeElement.id == 'search-word') {
      // search-wordをフォーカス中にEscを押下した場合、フォーカスをはずす
      event.preventDefault();
      document.activeElement.blur();
    }

    if (searchTarget != '') {
      event.preventDefault();
      document.activeElement.blur();
      this.search(event, searchTarget);
    }

  }

  searchTargetChange = (target) => {
    this.setState({ searchTarget:target, });
  }

  searchWordChange = (event) => {
    this.setState({ searchWord:event.target.value, });
  }

  searchWordKeyPress = (event) => {
  }

  search = (event, searchTarget) => {
    this.props.search(event, searchTarget, this.state.searchWord);
  }


  render() {

    return (
      <SearchBlockStyle>
        <div className={'searchbox'}>
          <input id='search-word' type='text' name='search' value={this.state.searchWord} onChange={ (event) => {this.searchWordChange(event)} } onKeyPress={ (event) => {this.searchWordKeyPress(event)} } placeholder='入力(i)' />
          <input type='submit' value='語彙素読み(r)' className={`search-btn reading ${this.state.searchTarget == 'reading' ? 'target' : ''}`} onClick={ (event) => {this.search(event, 'reading')} } />
          <input type='submit' value='語彙素(l)' className={`search-btn lemma ${this.state.searchTarget == 'lemma' ? 'target' : ''}`} onClick={ (event) => {this.search(event, 'lemma')} } />
          <input type='submit' value='語形(f)' className={`search-btn formBase ${this.state.searchTarget == 'formBase' ? 'target' : ''}`} onClick={ (event) => {this.search(event, 'formBase')} } />
          <input type='submit' value='書字形(o)' className={`search-btn orthBase ${this.state.searchTarget == 'orthBase' ? 'target' : ''}`} onClick={ (event) => {this.search(event, 'orthBase')} } />
        </div>
      </SearchBlockStyle>
    );

  }
}

export default SearchBlock;
