import React from 'react';
import {SearchingWrapperStyle} from 'component/common/style/SearchingWrapper';

class SearchingWrapper extends React.Component {
  render() {
    return (
      <SearchingWrapperStyle>
        <h1>検索中<span className='dot dot1'>.</span><span className='dot dot2'>.</span><span className='dot dot3'>.</span></h1>
      </SearchingWrapperStyle>
    );
  }
}

export default SearchingWrapper;
